import React, { useEffect, useState } from 'react'
import Ticket from './ticket'
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { useApplication } from '../../../providers/application-provider'
import {
	Ticket as TicketType,
	TicketCategory as TicketCategoryType
} from '../../../types/application-types'
import { calculateAllowedAmountForOrRules, checkAndRulesAreSatisfied } from '../../../util'

type Props = {
  maxAmount: number
  disabled: boolean
  tickets: TicketType[]
  category: TicketCategoryType
  onSelectTickets: (ticketId: string, amount: number) => void
}

const TicketCategory: React.FC<Props> = ({
	tickets,
	category,
	disabled,
	maxAmount,
	onSelectTickets
}) => {
	const {
		application: { activeEvent }
	} = useApplication()

	/** State Management */
	const [ticketAvailability, setTicketAvailability] = useState<Record<string, {
		andRulesSatisfied: boolean;
		allowedAmountFromOrRules: number | null;
	}>>({})

	/** Methods */
	const renderTicketItems = () => tickets.map((ticket: TicketType, idx: number) => {
		const availability = ticketAvailability[ticket.id] || { 
			andRulesSatisfied: true, 
			allowedAmountFromOrRules: null 
		}

		const effectiveMaxAmount = availability.allowedAmountFromOrRules !== null
			? Math.min(maxAmount + ticket.amount, availability.allowedAmountFromOrRules)
			: maxAmount + ticket.amount

		return (
			<Ticket
				key={`ticket${idx}`}
				ticket={ticket}
				disabled={disabled || ticket.v <= 0 || category.v <= 0 || !availability.andRulesSatisfied || availability.allowedAmountFromOrRules === 0}
				maxAmount={effectiveMaxAmount}
				onSelectTickets={onSelectTickets}
			/>
			)
		})
		

	const renderAvailability = () => {
		if (category.availabilityIndicator === 'green') {
			return (
				<>
					<InformationCircleIcon
						className="flex -ml-0.5 h-5 w-5 shrink-0 self-start text-emerald-500"
						aria-hidden="true"
					/>
					<span className="text-sm font-medium text-emerald-500">
            TICKETS BESCHIKBAAR
					</span>
				</>
			)
		}
		if (category.availabilityIndicator === 'yellow') {
			return (
				<>
					<InformationCircleIcon
						className="flex -ml-0.5 h-5 w-5 shrink-0 self-start text-amber-500"
						aria-hidden="true"
					/>
					<span className="text-sm font-medium text-amber-500">
            NOG ENKELE TICKETS BESCHIKBAAR
					</span>
				</>
			)
		}
		if (category.availabilityIndicator === 'red') {
			return (
				<>
					<InformationCircleIcon
						className="flex -ml-0.5 h-5 w-5 shrink-0 self-start text-red-500"
						aria-hidden="true"
					/>
					<span className="text-sm font-medium text-red-500">
						{category.v <= 0 ? 'UITVERKOCHT' : 'BIJNA UITVERKOCHT'}
					</span>
				</>
			)
		}
	}

	useEffect(() => {
		if (!activeEvent?.tickets?.length) return
		const newAvailability = activeEvent!.tickets.reduce((acc, ticket) => {
			acc[ticket.id] = {
				andRulesSatisfied: checkAndRulesAreSatisfied(activeEvent, ticket),
				allowedAmountFromOrRules: calculateAllowedAmountForOrRules(activeEvent, ticket)
			}
			return acc
		}, {} as Record<string, any>)

		setTicketAvailability(newAvailability)
	}, [activeEvent?.tickets])

	return (
		<div id={`ticketCategory${category.ref}`} className="flex flex-col">
			<h3 className="text-xl font-bold mb-2">{category.name}</h3>
			<div className="flex gap-1 items-center">{renderAvailability()}</div>
			<div className="flex flex-col gap-4 mt-2">{renderTicketItems()}</div>
		</div>
	)
}

export default React.memo<Props>(TicketCategory)
