import React, { useMemo } from 'react'
import { classNames, formatMoney } from '../../../util'

type Props = {
  saleStatus: string
  startingPrice: number
  hideStartingPrice: boolean
  onNavigateToTickets: () => void
}

const BuyTickets: React.FC<Props> = ({
	saleStatus,
	startingPrice,
	hideStartingPrice,
	onNavigateToTickets
}) => {
	/** State Management */
	const ticketsFrom = useMemo(
		() => formatMoney(startingPrice),
		[startingPrice]
	)
	const eventOnSale = useMemo(() => saleStatus === 'onSale', [saleStatus])
	const eventEnded = useMemo(() => saleStatus === 'past', [saleStatus])
	const eventSoldOut = useMemo(() => saleStatus === 'soldOut', [saleStatus])

	return (
		<section aria-labelledby="buy-tickets">
			<div className="overflow-hidden rounded-lg bg-white shadow">
				<div className="p-6">
					{eventEnded ? (
						<h2 className="text-lg font-bold">De verkoop is afgelopen.</h2>
					) : (
						<>
							{eventSoldOut ? (
								<>
									<h2 className="text-lg font-bold mb-2">Uitverkocht</h2>
									<div className="text-base font-medium text-gray-500">
                    Kom later terug om te kijken of er weer tickets beschikbaar
                    zijn.
									</div>
								</>
							) : (
								<>
									{!hideStartingPrice && (
										<div className="flex-column mb-6">
											<h2
												className="text-base font-medium text-gray-500"
												id="tickets-from"
											>
                        Tickets vanaf
											</h2>
											<div className="font-bold text-lg text-gray-900">
												{ticketsFrom}
											</div>
										</div>
									)}
									<div className="w-full">
										<button
											className={classNames(!eventOnSale ? 'cursor-not-allowed' : '', 'inline-flex justify-center items-center gap-x-1.5 rounded-md bg-primary px-5 py-3 text-md font-semibold text-accessible shadow-sm hover:bg-primary-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary w-full')}
											type="button"
											disabled={!eventOnSale}
											onClick={onNavigateToTickets}
										>
                      Koop tickets
										</button>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</section>
	)
}

export default React.memo<Props>(BuyTickets)
