import React from 'react'

type Props = {
  labelledby: string
  children: React.ReactNode
}

const ContentLeft: React.FC<Props> = ({ labelledby, children }) => (
	<section
		className="grid grid-cols-1 gap-4 lg:col-span-2"
		aria-labelledby={labelledby}
	>
		<div className="rounded-lg bg-white shadow p-6">{children}</div>
	</section>
)

export default React.memo<Props>(ContentLeft)
