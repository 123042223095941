import { FormatMoney } from 'format-money-js'
import {
	Event,
	ShoppingCart,
	ShoppingCartItem,
	ShoppingCartItemTicket,
	Ticket,
	TicketCategory,
	TicketState,
	ProductState,
	ShoppingCartItemProduct,
	FixedPriceProduct,
	RangePriceProduct
} from './types/application-types'
import { ApiCheckout, ApiCheckoutItem, ApiCheckoutItemProduct, ApiCheckoutItemTicket, ApiCoupon, ApiEvent, ApiEventCategory, ApiEventTicket, ApiProduct, DiscountType, PriceType } from './types/api-types'

const mapTicketCategories = (categories: ApiEventCategory[]): TicketCategory[] =>
	categories.map(({ listWithoutSeats, maxAmountPerOrder, name, ref, availabilityIndicator, v }) => ({
		listWithoutSeats,
		availabilityIndicator,
		maxAmountPerOrder,
		name,
		ref,
		v
	}))

const mapTickets = (tickets: ApiEventTicket[]): Ticket[] =>
	tickets.map(
		({
			id,
			price,
			name,
			description,
			active,
			taxRate,
			categoryRef,
			// dynamicFees,
			minAmountPerOrder,
			styleOptions,
			conditionalAvailability,
			conditionalAvailabilityMode,
			rules,
			v
		}) => ({
			id,
			name,
			price,
			active,
			taxRate,
			description,
			amount: 0,
			categoryRef,
			// dynamicFees,
			minAmountPerOrder,
			state: TicketState.INIT,
			styleOptions,
			conditionalAvailability,
			conditionalAvailabilityMode,
			rules,
			v
		})
	)

const mapSeller = ({ _id, name, customLogo, defaultLanguage, url }: any) => ({
	_id,
	name,
	customLogo,
	defaultLanguage,
	url
})

const mapEvent = (eventInfo: ApiEvent): Event | null => {
	if (eventInfo == null) return null
	const {
		_id,
		url,
		name,
		image,
		accentColor,
		description,
		timezone,
		start,
		location,
		saleStatus,
		startingPrice,
		seller,
		tickets,
		categories,
		styleOptions,
		min,
		max,
		upsellSettings,
		groups
	} = eventInfo
	return {
		_id,
		url,
		name,
		start,
		image,
		timezone,
		location,
		saleStatus,
		accentColor,
		description,
		startingPrice,
		hideStartingPrice: styleOptions?.hideStartingPrice ?? true,
		showAvailabilityIndicator: styleOptions?.showAvailabilityIndicator ?? false,
		seller: mapSeller(seller),
		tickets: mapTickets(tickets),
		categories: mapTicketCategories(categories),
		min,
		max,
		upsellSettings,
		groups
	}
}

const mapProducts = (products: ApiProduct[]): (FixedPriceProduct | RangePriceProduct)[] =>
	products.filter(({ active }: ApiProduct) => active).map(({ _id, name, image, description, variants, isFulfillable, type }: ApiProduct): (FixedPriceProduct | RangePriceProduct) => {
		if (variants[0].priceType === PriceType.fixed) {
			const { price, compareAtPrice, priceType, _id: productVariantId, taxRate, taxable } = variants[0]
			return {
				_id,
				name,
				description,
				image,
				price,
				compareAtPrice,
				priceType,
				productVariantId,
				amount: 0,
				state: ProductState.INIT,
				taxRate,
				taxable,
				isFulfillable,
				type
			}
		} else {
			const { compareAtPrice, priceType, _id: productVariantId, taxRate, taxable, priceRange: { min, max } } = variants[0]
			return {
				_id,
				name,
				description,
				image,
				price: min,
				compareAtPrice,
				priceType,
				min,
				max,
				productVariantId,
				amount: 0,
				state: ProductState.INIT,
				taxRate,
				taxable,
				isFulfillable,
				type
			}
		}
		
	})

const mapShoppingCartItemTickets = (tickets: ApiCheckoutItemTicket[]): ShoppingCartItemTicket[] =>
	tickets.map(({ name, price, amount, ticketTypeId, seatingInfo }) => ({
		name,
		price,
		amount,
		ticketTypeId,
		seatingInfo
	}))

const mapShoppingCartItemProducts = (products: ApiCheckoutItemProduct[]): ShoppingCartItemProduct[] => 
	products.map(({ _id, amount, name, price }) => ({
		_id,
		amount,
		name,
		price
	}))

const mapShoppingCartItems = (items: ApiCheckoutItem[]): ShoppingCartItem[] =>
	items.map(
		({ _id, eventId, innerCharge, outerCharge, regularPrice, tickets, products }) => ({
			_id,
			eventId,
			innerCharge,
			outerCharge,
			regularPrice,
			tickets: mapShoppingCartItemTickets(tickets),
			products: mapShoppingCartItemProducts(products)
		})
	)

const mapShoppingCart = (checkout: ApiCheckout): ShoppingCart => {
	// console.log('api shopping cart data', info)
	const { _id, createdAt, expiresAt, realPrice, secret, status, items } = checkout
	return {
		_id,
		createdAt,
		expiresAt,
		realPrice,
		secret,
		status,
		items: mapShoppingCartItems(items)
	}
}

const formatMoney = (amount: number) => {
	const fm = new FormatMoney({
		decimals: 2,
		symbol: '€ ',
		decimalPoint: ','
	})
	const formattedAmount = fm.from(Math.abs(amount))!.toString()
  return amount < 0 ? formattedAmount.replace('€ ', '€ -') : formattedAmount
}

const formatMoneyWithoutSymbol = (price: number) => {
	const fm = new FormatMoney({
		decimals: 2,
		decimalPoint: ','
	})
	return fm.from(price)!.toString()
}

const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ')
}

const getRGBColorVar = (hex: string, type: string) => {
	const color = hex.replace(/#/g, '')
	// rgb values
	const r = parseInt(color.substring(0, 2), 16)
	const g = parseInt(color.substring(2, 4), 16)
	const b = parseInt(color.substring(4, 6), 16)

	return `--color-${type}: ${r}, ${g}, ${b};`
}

const getAccessibleColor = (hex: string) => {
	const color = hex.replace(/#/g, '')
	// rgb values
	const r = parseInt(color.substring(0, 2), 16)
	const g = parseInt(color.substring(2, 4), 16)
	const b = parseInt(color.substring(4, 6), 16)
	const yiq = (r * 299 + g * 587 + b * 114) / 1000
	return yiq >= 128 ? '#000000' : '#FFFFFF'
}

const calculateCouponReduction = (coupon: ApiCoupon, shoppingCart: ShoppingCart): number => {
	const { 
		discountType, 
		discountValue, 
		discountMaxValue, 
		allowAllTickets,
		limitMaxTicketsInCart, 
		maxTicketsInCart, 
		allowedTickets 
	} = coupon

	const cartItems = shoppingCart?.items ?? []
	let totalDiscount = 0

	if (discountType === DiscountType.FIX_PER_ITEM) {
		// If discountType is FIX_PER_ITEM and only applicable to a specific ticket type, products don't count.
		// If allowAllTickets is true, all products count.
		// If allowAllTickets is false and allowedTickets is empty, no tickets count or products count.
		let applicableTicketCount = 0
		let applicableProductCount = 0

		cartItems.forEach((item) => {
			// Count applicable tickets
			item.tickets.forEach((ticket) => {
				if (allowAllTickets || (!allowAllTickets && allowedTickets.includes(ticket.ticketTypeId))) {
					applicableTicketCount += ticket.amount
				}
			})

			// Count products if allowAllTickets is true
			if (allowAllTickets) {
				item.products.forEach((product) => {
					applicableProductCount += product.amount
				})
			}
		})

		// Apply maxTicketsInCart limit only to tickets
		if (limitMaxTicketsInCart) {
			applicableTicketCount = Math.min(applicableTicketCount, maxTicketsInCart)
		}

		// Calculate total discount
		totalDiscount = discountValue * (applicableTicketCount + applicableProductCount)

		if (discountMaxValue !== undefined && discountMaxValue > 0) {
			totalDiscount = Math.min(totalDiscount, discountMaxValue)
		}
	} else if (discountType === DiscountType.FIX) {
		// If allowAllTickets is true, all tickets count.
		// If allowAllTickets is false and allowedTickets is empty, no tickets count.
		// If allowAllTickets is false and allowedTickets is not empty, 
		// then the discount is only applied to the total cart value if a ticket of allowedTickets is in the cart.
		let hasAllowedTicket = false
    let totalCartAmount = 0

    cartItems.forEach((item) => {
      item.tickets.forEach(ticket => {
        if (!allowAllTickets && allowedTickets.includes(ticket.ticketTypeId)) {
          hasAllowedTicket = true
        }
        totalCartAmount += ticket.price * ticket.amount
      })

      // Include products in total cart amount
      item.products.forEach((product) => {
        totalCartAmount += product.price * product.amount
      })
    })

    if (allowAllTickets || hasAllowedTicket) {
      totalDiscount = Math.min(discountValue, totalCartAmount)
    }
	} else {
		// If allowAllTickets is true, limitMaxTicketsInCart is false, all tickets/products count.
		// If allowAllTickets is true, limitMaxTicketsInCart is true, all tickets/products count, capped by maxTicketsInCart most expensive tickets/products.
		// If allowAllTickets is false and allowedTickets is empty, no tickets/products count.
		// If allowAllTickets is false and allowedTickets is not empty, limitMaxTicketsInCart is false, all tickets of allowedTickets count.
		// If allowAllTickets is false and allowedTickets is not empty, limitMaxTicketsInCart is true, only maxTicketsInCart tickets of allowedTickets count.
    const eligibleItems: { price: number; amount: number }[] = []

    cartItems.forEach((item) => {
      item.tickets.forEach((ticket) => {
        if (allowAllTickets || allowedTickets.includes(ticket.ticketTypeId)) {
          eligibleItems.push({ price: ticket.price, amount: ticket.amount })
        }
      })

      // Include products only if allowAllTickets is true
      if (allowAllTickets) {
        item.products.forEach((product) => {
          eligibleItems.push({ price: product.price, amount: product.amount })
        })
      }
    })

    if (allowAllTickets || (allowedTickets.length > 0 && eligibleItems.length > 0)) {
      if (limitMaxTicketsInCart) {
        // Sort eligible items by price (descending)
        eligibleItems.sort((a, b) => b.price - a.price)
        
        let itemCount = 0
        let totalEligibleAmount = 0
        
        for (const item of eligibleItems) {
          if (itemCount + item.amount <= maxTicketsInCart) {
            totalEligibleAmount += item.price * item.amount
            itemCount += item.amount
          } else {
            const remainingSlots = maxTicketsInCart - itemCount
            totalEligibleAmount += item.price * remainingSlots
            break
          }
        }

        // Calculate percentage discount
        totalDiscount = totalEligibleAmount * discountValue
      } else {
        const totalEligibleAmount = eligibleItems.reduce((sum, item) => sum + item.price * item.amount, 0)
        totalDiscount = totalEligibleAmount * discountValue
      }

      if (discountMaxValue != null && discountMaxValue > 0) {
        totalDiscount = Math.min(totalDiscount, discountMaxValue)
      }
    }
  }

	return totalDiscount
}

const calculateTotalCouponReduction = (coupons: ApiCoupon[], shoppingCart: ShoppingCart): number => {
	return coupons.reduce((total, coupon) => total + calculateCouponReduction(coupon, shoppingCart), 0)
}

const mapCheckoutResponseToCoupons = (checkoutResponse: ApiCheckout): ApiCoupon[] => {
  const coupons: ApiCoupon[] = []

  checkoutResponse.items.forEach((item) => {
    item.appliedCoupons.forEach((appliedCoupon) => {
      const discountInfo = item.appliedDiscountInfo.discounts.find(
        (discount) => discount.discountId === appliedCoupon._id
      )
      if (discountInfo) {
        const apiCoupon: ApiCoupon = {
          canBeCombined: true, // Assuming this is always true, adjust if needed
          discountType: mapDiscountType(discountInfo.type),
          discountValue: discountInfo.value,
          discountMaxValue: discountInfo.maxAbsoluteValue ?? 0,
          allowAllTickets: discountInfo.allowedItems.length === 0,
          allowAllEvents: true, // Assuming this is always true, adjust if needed
          limitMaxTicketsInCart: discountInfo.maxItemsInCart !== null,
          maxTicketsInCart: discountInfo.maxItemsInCart ?? 0,
          maxTickets: discountInfo.maxItemsInCart ?? 0,
          unlocks: {}, // Assuming this is always an empty object, adjust if needed
          code: appliedCoupon.code,
          couponId: appliedCoupon._id,
          name: discountInfo.name,
          allowedTickets: discountInfo.allowedItems
        }

        coupons.push(apiCoupon)
      }
    })
  })

  return coupons
}

const mapDiscountType = (type: string): DiscountType => {
  switch (type) {
    case 'fixPerItem':
      return DiscountType.FIX_PER_ITEM
    case 'fix':
      return DiscountType.FIX
    case 'var':
      return DiscountType.VAR
    default:
      throw new Error(`Unknown discount type: ${type}`)
  }
}

const checkAndRulesAreSatisfied = (activeEvent: Event, ticket: Ticket): boolean => {
	if (!ticket.conditionalAvailability || !ticket.rules?.length) {
		// No conditional availability, no restrictions
		return true 
	}

	// Filter for AND rules
	const andRules = ticket.rules.filter((rule) => rule.concatenation === 'AND')
	if (!andRules.length) return true

	// All AND rules must be satisfied
	return andRules.every((rule) => {
		// Find corresponding group
		const group = activeEvent!.groups.find((g) => g._id === rule.ticketGroup)
		if (!group) return true

		// Calculate sum of tickets in cart for this group
		const sumOfGroupTickets = activeEvent.tickets.reduce((sum, t) => {
			if (group.tickets.includes(t.id)) {
				// const ticketInCart = tickets.find((cartTicket) => cartTicket.id === t.id)
				return sum + (t.amount || 0)
			}
			return sum
		}, 0)

		// Check if sum is within min-max range
		return sumOfGroupTickets >= rule.min && sumOfGroupTickets <= rule.max
	})
}

const calculateAllowedAmountForOrRules = (activeEvent: Event, ticket: Ticket): number | null => {
	if (!ticket.conditionalAvailability || !ticket.rules?.length) {
		// No conditional availability, no restrictions
		return null 
	}

	// Filter for OR rules
	const orRules = ticket.rules.filter((rule) => rule.concatenation === 'OR')
	if (!orRules.length) return null

	let maxAllowedAmount = 0

	// Check each OR rule
	orRules.forEach((rule) => {
		const group = activeEvent!.groups.find((g) => g._id === rule.ticketGroup)
		if (!group) return

		// Calculate sum of tickets in cart for this group
		const sumOfGroupTickets = activeEvent!.tickets.reduce((sum, t) => {
			if (group.tickets.includes(t.id)) {
				return sum + (t.amount || 0)
			}
			return sum
		}, 0)

		// If this rule's conditions are met, update maxAllowedAmount if higher
		if (sumOfGroupTickets >= rule.min && sumOfGroupTickets <= rule.max) {
			maxAllowedAmount = Math.max(maxAllowedAmount, rule.maxAmountPerOrder)
		}
	})

	return maxAllowedAmount
}

export {
	mapEvent,
	mapProducts,
	mapShoppingCart,
	mapShoppingCartItemProducts,
	formatMoney,
	formatMoneyWithoutSymbol,
	classNames,
	getRGBColorVar,
	getAccessibleColor,
	calculateCouponReduction,
	calculateTotalCouponReduction,
	mapCheckoutResponseToCoupons,
	checkAndRulesAreSatisfied,
	calculateAllowedAmountForOrRules
}
