import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EventInfoPage from './pages/event-info'
import TicketsPage from './pages/tickets'
import ProductsPage from './pages/products'
import NotFound from './pages/not-found'

import './App.css'

function App() {
	return (
		<Routes>
			<Route path="/event/:id" element={<EventInfoPage />} />
			<Route path="/checkout/:id" element={<EventInfoPage />} />
			<Route path="/event/:id/tickets" element={<TicketsPage />} />
			<Route path="/event/:id/products" element={<ProductsPage />} />
			<Route path="/checkout/:id/tickets" element={<TicketsPage />} />
			<Route path="/checkout/:id/products" element={<ProductsPage />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default App
