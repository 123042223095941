import React from 'react'

type Props = {
  children?: React.ReactNode
}

const Content: React.FC<Props> = ({ children }) => (
	<main className="-mt-56 pb-8 relative z-10">
		<div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
			{children}
		</div>
	</main>
)

export default React.memo<Props>(Content)
