import React, { useContext, useEffect, useReducer } from 'react'
import { Oval } from 'react-loader-spinner'
import { matchPath, useLocation, useSearchParams } from 'react-router-dom'
import {
	changeTicketStateAction,
	changeProductStateAction,
	clearTicketsAction,
	reserveTicketsAction,
	reserveProductsAction,
	setActiveEventAction,
	setCreatingCheckoutAction,
	setCheckoutAction,
	setCheckoutExpiredAction,
	setRehydratingAction,
	setRehydratingCheckoutAction,
	setShoppingCartAction,
	setProductsAction,
	addCouponAction,
	removeCouponAction
} from './reducer-actions'
import { fetchCheckout, fetchEventInfo, fetchProducts, fetchPromotionCode } from '../../api-handler'
import {
	getAccessibleColor,
	getRGBColorVar,
	mapCheckoutResponseToCoupons,
	mapEvent,
	mapProducts,
	mapShoppingCart
} from '../../util'
import { Event, ShoppingCart, TicketState, FixedPriceProduct, RangePriceProduct, ProductState } from '../../types/application-types'
import { ApiCheckout, ApiProduct, ApiCoupon, ApiCheckoutItem, ApiAppliedCoupon } from '../../types/api-types'
import reducer from './reducer'
import { useReserveHook } from '../../useReserveHook'
import NotFound from '../../pages/not-found'

export type ApplicationContextType = {
	creatingCheckout: boolean
  rehydrating: boolean
  rehydratingCheckout: boolean
	checkout: ApiCheckout | null
	checkoutExpired: boolean
  activeEvent: Event | null
	products: (FixedPriceProduct | RangePriceProduct)[]
  shoppingCart: ShoppingCart | null
	coupons: ApiCoupon[]
	setCreatingCheckout: (value: boolean) => void
  setRehydrating: (value: boolean) => void
  setRehydratingCheckout: (value: boolean) => void
  setActiveEvent: (eventInfo: Event | null) => void
	setProducts: (products: (FixedPriceProduct | RangePriceProduct)[]) => void
  reserveTickets: (ticketId: string, amount: number) => void
	reserveProducts: (productId: string, amount: number, price?: number) => void
  clearTickets: () => void
	setCheckout: (checkout: ApiCheckout | null) => void
	setCheckoutExpired: (checkoutExpired: boolean) => void
  setShoppingCart: (shoppingCart: ShoppingCart | null, coupons?: ApiCoupon[]) => void
  changeTicketState: (ticketId: string, state: TicketState, amountLeft?: number) => void
	changeProductState: (productId: string, state: ProductState) => void
	addCoupon: (coupon: ApiCoupon) => void
	removeCoupon: (couponId: string) => void
}

export const useApplication = (): { application: ApplicationContextType } => {
	const context = useContext(ApplicationContext)
	if (context == null) {
		throw new Error('useApplication must be used within a ApplicationProvider')
	}

	return context
}

const initState = {
	rehydrating: false,
	rehydratingCheckout: false,
	creatingCheckout: false,
	checkout: null,
	activeEvent: null,
	coupons: [],
	products: [],
	shoppingCart: null,
	checkoutExpired: false,
	showCheckoutExpiredModal: false
}

const ApplicationContext = React.createContext<{ application: ApplicationContextType } | undefined>(undefined)

const useProvideApplication = (): ApplicationContextType => {
	const [applicationState, dispatchAction] = useReducer(reducer, initState)

	const setRehydrating = (value: boolean) => dispatchAction(setRehydratingAction(value))
	
	const setRehydratingCheckout = (value: boolean) => dispatchAction(setRehydratingCheckoutAction(value))

	const setCreatingCheckout = (value: boolean) => dispatchAction(setCreatingCheckoutAction(value))

	const setCheckout = (checkout: ApiCheckout | null) => dispatchAction(setCheckoutAction(checkout))

	const setActiveEvent = (eventInfo: Event | null) => dispatchAction(setActiveEventAction(eventInfo))

	const setShoppingCart = (shoppingCart: ShoppingCart | null, coupons?: ApiCoupon[]) => dispatchAction(setShoppingCartAction(shoppingCart, coupons))

	const reserveTickets = (ticketId: string, amount: number) => dispatchAction(reserveTicketsAction(ticketId, amount))

	const reserveProducts = (productId: string, amount: number, price?: number) => dispatchAction(reserveProductsAction(productId, amount, price))

	const clearTickets = () => dispatchAction(clearTicketsAction())

	const changeTicketState = (ticketId: string, state: TicketState, amountLeft?: number) => dispatchAction(changeTicketStateAction(ticketId, state, amountLeft))

	const changeProductState = (productId: string, state: ProductState) => dispatchAction(changeProductStateAction(productId, state))

	const setProducts = (products: (FixedPriceProduct | RangePriceProduct)[]) => dispatchAction(setProductsAction(products))

	const setCheckoutExpired = (checkoutExpired: boolean) => dispatchAction(setCheckoutExpiredAction(checkoutExpired))

	const addCoupon = (coupon: ApiCoupon) => dispatchAction(addCouponAction(coupon))

	const removeCoupon = (couponId: string) => dispatchAction(removeCouponAction(couponId))

	const { rehydrating, rehydratingCheckout, checkout, activeEvent, shoppingCart, creatingCheckout, products, checkoutExpired, coupons } = applicationState

	return {
		creatingCheckout,
		rehydrating,
		rehydratingCheckout,
		checkout,
		checkoutExpired,
		activeEvent,
		products,
		shoppingCart,
		coupons,
		reserveTickets,
		reserveProducts,
		setCreatingCheckout,
		setCheckout,
		setRehydrating,
		setRehydratingCheckout,
		setActiveEvent,
		setShoppingCart,
		changeTicketState,
		changeProductState,
		clearTickets,
		setProducts,
		setCheckoutExpired,
		addCoupon,
		removeCoupon
	}
}

type Props = {
  children: React.ReactNode
}

const ApplicationProvider: React.FC<Props> = ({ children }) => {
	const application = useProvideApplication()
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()

	const {
		activeEvent,
		shoppingCart,
		creatingCheckout,
		rehydrating,
		setRehydrating,
		setRehydratingCheckout,
		setActiveEvent,
		setProducts,
		setShoppingCart,
		setCheckout
	} = application

	const fetchActiveEvent = async (eventUrl: string, callBack?: () => void) =>
		fetchEventInfo(eventUrl)
			.then(async (eventResponse) => {
				const eventInfo = mapEvent(eventResponse.data)
				if (eventInfo?.upsellSettings.active && eventInfo.upsellSettings.productStream) {
					fetchProducts(eventInfo!.upsellSettings.productStream).then(({ data }: { data: ApiProduct[]}) => {
						setProducts(mapProducts(data))
						setActiveEvent(eventInfo)
						if (callBack) callBack()
					}).catch(() => setRehydrating(false))
				} else {
					setActiveEvent(eventInfo)
					if (callBack) callBack()
				}
			})
			.catch(() => setRehydrating(false))

	const fetchShoppingCart = async (checkoutId: string, secret: string) => {
		fetchCheckout(checkoutId, secret)
			.then(async (res) => {
				const appliedCoupons = res.data.items.flatMap((item: ApiCheckoutItem) => item.appliedCoupons)
				const couponResults = await Promise.all(
					appliedCoupons.map((appliedCoupon: ApiAppliedCoupon) => 
						fetchPromotionCode(appliedCoupon.code, activeEvent!.seller._id, activeEvent!._id)
					)
				)
				const coupons = couponResults.map((result) => result.data.coupon)
				setCheckout(res.data)
				const shoppingCart = mapShoppingCart(res.data)
				if (shoppingCart.status === 'NEW') {
					setShoppingCart(shoppingCart, coupons)
				} else {
					// Cleanup url
					const newSearchParams = new URLSearchParams(searchParams)
					newSearchParams.delete('checkoutId')
					newSearchParams.delete('secret')
					setSearchParams(newSearchParams)
				}
				setRehydratingCheckout(false)
			})
			.catch(() => console.error('Error fetching checkout'))
	}

	/** Fetch event info */
	useEffect(() => {
		const needEvent = matchPath('/event/*', location.pathname) != null
		const needEventSecretShop = matchPath('/checkout/*', location.pathname) != null

		const eventUrl = location.pathname.split('/')[2]

		// A secret shop uses /checkout/eventId instead of /event/eventUrl
		if ((needEvent && (activeEvent == null || activeEvent.url !== eventUrl) && !rehydrating) || (needEventSecretShop && (activeEvent == null || activeEvent._id !== eventUrl))) {
			// We need an event and we have no event or we have another one.
			setRehydrating(true)
			fetchActiveEvent(eventUrl, () => setRehydrating(false))
		}
	}, [activeEvent, location.pathname, rehydrating])

	/** Fetch shopping cart */
	useEffect(() => {
		const checkoutId = searchParams.get('checkoutId')
		const secret = searchParams.get('secret')

		if (!creatingCheckout && activeEvent && shoppingCart == null && checkoutId != null && secret != null) {
			setRehydratingCheckout(true)
			fetchShoppingCart(checkoutId, secret)
		}
	}, [activeEvent, creatingCheckout, shoppingCart, searchParams])

	useReserveHook(activeEvent, application)

	useEffect(() => {
		if (activeEvent) {
			const primaryColor = getRGBColorVar(activeEvent.accentColor, 'primary')
			const accessibleColor = getRGBColorVar(getAccessibleColor(activeEvent.accentColor), 'accessible')
			const head = document.querySelector('head')
			if (head && head.textContent && !head.textContent.includes(':root')) {
				head.innerHTML += `
          <style>
            :root {
              ${primaryColor};
              ${accessibleColor};
            }
          </style>
        `
			}

			// Change document title to contain event name.
			document.title = `Tickets for ${activeEvent.name}`
		}
	}, [activeEvent])

	if (rehydrating) {
		return (
			<div className="flex flex-1 items-center justify-center w-full">
				<Oval
					visible
					height="40"
					width="40"
					color="#000000"
					secondaryColor="#35ac3b"
					ariaLabel="oval-loading"
				/>
			</div>
		)
	} else if (!rehydrating && !activeEvent) {
		return <NotFound message="Dit evenement bestaat niet!" />
	}

	return (
		<ApplicationContext.Provider value={{ application }}>
			{children}
		</ApplicationContext.Provider>
	)
}

export default ApplicationProvider
