import moment from 'moment'
import pluralize from 'pluralize'
import { Oval } from 'react-loader-spinner'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ShoppingCartItem from './shopping-cart-item'
import ShoppingCartExpired from './modals/shopping-cart-expired'
import ShoppingCartCountdownTimer from './shopping-cart-countdown-timer'
import { useApplication } from '../../providers/application-provider'
import { fetchCheckout } from '../../api-handler'
import { calculateCouponReduction, formatMoney } from '../../util'
import ShoppingCartCoupon from './shopping-cart-coupon'
import { ApiCoupon } from '../../types/api-types'
import ShoppingCartCouponModal from './modals/shopping-cart-coupon-modal'


type Props = {
  persisting: boolean
}

const ShoppingCart: React.FC<Props> = ({ persisting }) => {
	const {
		application: {
			activeEvent,
			products,
			shoppingCart,
			rehydratingCheckout,
			checkoutExpired,
			coupons,
			setShoppingCart,
			setCheckout,
			setCheckoutExpired,
			clearTickets
		}
	} = useApplication()
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	/** State Management */
	const [fetchingCheckout, setFetchingCheckout] = useState<boolean>(false)
	const [couponModalOpen, setCouponModalOpen] = useState<boolean>(false)

	const hasItems = useMemo(() => {
		if (shoppingCart) {
			const eventItem = shoppingCart.items.find(
				({ eventId }) => eventId === activeEvent!._id
			)
			if (eventItem) return eventItem.tickets.length > 0
			return false
		}
		return false
	}, [shoppingCart])

	// const allTicketsHaveASeat = useMemo(() => {
	// 	if (shoppingCart) {
	// 		// eslint-disable-next-line
	// 		debugger
	// 		const eventItem = shoppingCart.items.find(({ eventId }) => eventId === activeEvent!._id)
	// 		if (eventItem) {
	// 			return eventItem.tickets.every((ticket) => ticket.seatingInfo != null)
	// 		}
	// 		return false
	// 	}
	// 	return false
	// }, [shoppingCart])

	const noTicketsHaveASeat = useMemo(() => {
		if (shoppingCart) {
			const eventItem = shoppingCart.items.find(({ eventId }) => eventId === activeEvent!._id)
			if (eventItem) {
				return eventItem.tickets.every((ticket) => ticket.seatingInfo == null)
			}
			return false
		}
		return false
	}, [shoppingCart])

	const totalPrice = useMemo(() => {
		if (!shoppingCart) return formatMoney(0)
		return formatMoney(shoppingCart.realPrice)
	}, [coupons, shoppingCart])

	const totalReserveTime = useMemo(() => {
		if (!shoppingCart) return 0
		return Math.round(
			moment(shoppingCart.expiresAt).diff(
				shoppingCart.createdAt,
				'minutes',
				true
			)
		)
	}, [shoppingCart])

	/** Methods */
	const openCouponModal = () => {
		setCouponModalOpen(true)
	}

	const closeCouponModal = () => {
		setCouponModalOpen(false)
	}

	const onNavigateToCheckoutDetails = async () => {
		if (activeEvent?.upsellSettings.active && location.pathname.includes('/tickets') && products.length > 0) {
			// We are on tickets, upsell is active and we have products
			// navigate(`products?${searchParams}`)
			navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/products?${searchParams}`)
		} else {
			setFetchingCheckout(true)
			return fetchCheckout(shoppingCart!._id, shoppingCart!.secret)
				.then((res) => {
					if (res.data.status === 'NEW') {
						window.location.href = `${process.env.REACT_APP_ENDPOINT}/seller/${activeEvent!.seller._id}/checkout/details?checkoutId=${shoppingCart!._id}&secret=${shoppingCart!.secret}&lang=nl`
					} else {
						setFetchingCheckout(false)
						setCheckoutExpired(true)
					}
				})
				.catch(() => setFetchingCheckout(false))
		}
	}

	const onResetPurchase = () => {
		// Cleanup url
		const newSearchParams = new URLSearchParams(searchParams)
		newSearchParams.delete('checkoutId')
		newSearchParams.delete('secret')
		setSearchParams(newSearchParams)

		// Cleanup state
		setShoppingCart(null)
		setCheckout(null)
		setCheckoutExpired(false)
		clearTickets()
		navigate(`${location.pathname.split('/').slice(0, -1).join('/')}?${newSearchParams}`)
		// setOpenExpired(false)
	}

	/** Render Methods */
	const renderItems = () =>
    shoppingCart!.items.map((item) => (<ShoppingCartItem item={item} key={`shoppingCartItem${item._id}`} />))

	const renderCoupons = () => {
		return coupons.map((coupon: ApiCoupon) => (<ShoppingCartCoupon key={coupon.couponId} coupon={coupon} reduction={shoppingCart ? calculateCouponReduction(coupon, shoppingCart) : 0} />))
	}

	const renderReservationTime = () => {
		if (totalReserveTime < 60) {
			return `Wij reserveren uw tickets ${totalReserveTime} minuten voor jou.`
		} else {
			const hours = Math.floor(totalReserveTime / 60)
			const minutes = totalReserveTime % 60
			return `Wij reserveren uw tickets ${pluralize('uur', hours)} en ${pluralize('minuut', minutes)} voor jou.`
		}
	}

	return (
		<>
			<section aria-labelledby="event-data">
				<div className="overflow-hidden rounded-lg bg-white shadow">
					<div className="flex flex-col p-6">
						<div className="flex justify-between items-center pb-1.5">
							<h3 className="text-xl font-bold">Winkelwagen</h3>
							{shoppingCart && (<ShoppingCartCountdownTimer expireTime={shoppingCart.expiresAt} />)}
						</div>
						{rehydratingCheckout ? (
							<div className="flex justify-center self-center min-w-20 py-5">
								<Oval
									visible
									height="30"
									width="30"
									color={activeEvent?.accentColor ?? '#000000'}
									secondaryColor={`${activeEvent?.accentColor ?? '#000000'}80`}
									ariaLabel="rehydrating-checkout"
								/>
							</div>
						) : (
							<div className="divide-y divide-gray-200">
								<ul role="list" className="divide-y divide-gray-200 sm:max-h-[250px] sm:overflow-y-auto">
									{hasItems ? (
										<>
											{persisting ? (
												<div className="flex justify-center self-center min-w-20 py-5">
													<Oval
														visible
														height="30"
														width="30"
														color={activeEvent?.accentColor ?? '#000000'}
														secondaryColor={`${
															activeEvent?.accentColor ?? '#000000'
														}80`}
														ariaLabel="persisting-tickets"
													/>
												</div>
											) : (
												renderItems()
											)}
										</>
									) : (
										<li
											key="empty"
											className="text-base font-medium text-gray-500 py-4"
										>
                      Het winkelmandje is leeg.
										</li>
									)}
								</ul>
								{coupons.length > 0 && (
									<div className="flex flex-col pt-4 justify-between">
										<h3 className="text-xl font-bold">Kortingen</h3>
										<ul role="list" className="divide-y divide-gray-200">
											{renderCoupons()}
										</ul>
									</div>
								)}
								{hasItems && !noTicketsHaveASeat && (
									<>
										<div
											key="totals"
											className="text-base font-medium text-gray-800 py-2 pb-0"
										>
											<div className="flex justify-between font-bold">
												<div className="flex flex-col">
													<span>Totaal</span>
													<span className="text-sm font-medium text-gray-500">
                            incl. fees
													</span>
												</div>
												<span>{totalPrice}</span>
											</div>
										</div>
										<div className="mt-3 w-full">
											{coupons.length === 0 || coupons.every(({ canBeCombined }) => canBeCombined) ? (
												<div className="text-base flex w-full py-4 justify-center">
													<button className="font-bold" type='button' onClick={openCouponModal}>Kortingscode inwisselen</button>
												</div>
											) : null}
											<button
												type="button"
												className={`inline-flex justify-center items-center gap-x-1.5 rounded-md bg-primary px-5 py-3 text-md font-semibold text-accessible shadow-sm hover:bg-primary-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary w-full ${/*!allTicketsHaveASeat ||*/ persisting ? 'cursor-not-allowed' : ''}`}
												disabled={/*!allTicketsHaveASeat ||*/ persisting}
												onClick={onNavigateToCheckoutDetails}
											>
												{fetchingCheckout ? (
													<Oval
														visible
														height="30"
														width="30"
														color="#FFFFFF"
														secondaryColor="#FFFFFF"
														ariaLabel="fetching-checkout"
													/>
												) : (
													'Details & Betalen'
												)}
											</button>
											{totalReserveTime > 0 && (
												<span className="inline-flex text-sm text-gray-500 mt-1.5">
													{renderReservationTime()}
												</span>
											)}
										</div>
									</>
								)}
								{!hasItems && (coupons.length === 0 || coupons.every(({ canBeCombined }) => canBeCombined)) && (
									<div className="text-base flex mt-6 w-full py-4 justify-center">
										<button className="font-bold" type='button' onClick={openCouponModal}>Kortingscode inwisselen</button>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</section>
			<ShoppingCartExpired open={checkoutExpired} onResetPurchase={onResetPurchase} />
			<ShoppingCartCouponModal open={couponModalOpen} onClose={closeCouponModal} />
		</>
	)
}

export default React.memo<Props>(ShoppingCart)
