import React from 'react'

type Props = {
  children: React.ReactNode
}

const Content: React.FC<Props> = ({ children }) => (
	<div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
		{children}
	</div>
)

export default React.memo<Props>(Content)
