import React from 'react'
import { formatMoney } from '../../util'
import Badge from '../badge'
import { useApplication } from '../../providers/application-provider'
import { ApiCoupon, DiscountType } from '../../types/api-types'

type Props = {
  coupon: ApiCoupon
	reduction: number
}

const ShoppingCartItemCoupon: React.FC<Props> = ({ coupon, reduction }) => {
	const {
		application: {
			removeCoupon
		}
	} = useApplication()
	/** State Management */

	const onClickRemoveCoupon = (couponId: string) => () => {
		removeCoupon(couponId)
	}

	const getBadge = () => {
		return coupon.discountType === DiscountType.VAR ? `${coupon.code} -${(coupon.discountValue * 100).toFixed(2)}%` : `${coupon.code} ${formatMoney(-coupon.discountValue)}`
	}

	return (
		<li className="text-base font-medium text-gray-800 py-2 flex flex-col">
			<div className="flex justify-between mb-0.5">
				<Badge badge={getBadge()} onRemove={onClickRemoveCoupon(coupon.couponId)} />
        <span>{`${formatMoney(-reduction)}`}</span>
			</div>
		</li>
	)
}

export default React.memo<Props>(ShoppingCartItemCoupon)
