import React, { useMemo } from 'react'
import { useApplication } from '../../providers/application-provider'

const Footer: React.FC = () => {
	const {
		application: { activeEvent }
	} = useApplication()

	/** State Management */
	const urls = useMemo(() => {
		if (!activeEvent) return {}
		return { 
			agb: `https://shop.ticket.monster/seller/${activeEvent.seller.url}/agb`,
			dataprivacy: `https://shop.ticket.monster/seller/${activeEvent.seller.url}/dataprivacy`,
			imprint: `https://shop.ticket.monster/seller/${activeEvent.seller.url}/imprint`,
			agbTicketMonster: 'https://shop.ticket.monster/agb',
			dataprivacyTicketMonster: 'https://shop.ticket.monster/dataprivacy'
		}
	}, [activeEvent])

	return (
		<footer>
			<div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex flex-wrap gap-3 py-4 text-sm text-gray-500">
					<a
						href={urls.imprint}
						target="_blank"
						className="text-sm leading-6 hover:text-gray-700" rel="noreferrer"
					>
              Over de organisator
					</a>
					<a
						href={urls.dataprivacy}
						target="_blank"
						className="text-sm leading-6 hover:text-gray-700" rel="noreferrer"
					>
              Privacybeleid van de organisator
					</a>
					<a
						href={urls.agb}
						target="_blank"
						className="text-sm leading-6 hover:text-gray-700" rel="noreferrer"
					>
              Algemene voorwaarden van de organisator
					</a>
				</div>
				<div className="flex border-t border-gray-200 py-4 text-center text-sm text-gray-500 sm:text-left justify-between items-center">
					<div className="flex mr-4">
						<a href="#">
							<span className="sr-only">ticket.monster</span>
							<img
								className="max-h-12 w-auto"
								src="https://s3.eu-central-1.amazonaws.com/yt-s3/71d93d29-0699-4e90-9a06-f9f92ce9f85d.png"
								alt="Ticket monster"
							/>
						</a>
					</div>
					<div className="flex flex-wrap gap-3 justify-end">
						<a
							href={urls.agbTicketMonster}
							target="_blank"
							className="text-sm leading-6 hover:text-gray-700" rel="noreferrer"
						>
              Algemene voorwaarden
						</a>
						<a
							href={urls.dataprivacyTicketMonster}
							target="_blank"
							className="text-sm leading-6 hover:text-gray-700" rel="noreferrer"
						>
              Gegevensprivacybeleid
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default React.memo(Footer)
