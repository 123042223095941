import 'moment/locale/nl-be'
import React, { useMemo } from 'react'
import momentTZ from 'moment-timezone'
import MapPinIcon from '@heroicons/react/24/outline/MapPinIcon'
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon'
import { EventLocation } from '../../../types/application-types'

type Props = {
  startDate: string
  timezone: string
  location: EventLocation
}

const EventData: React.FC<Props> = ({ startDate, timezone, location }) => {
	/** State Management */
	const adress = useMemo(
		() =>
			`${location.locationStreet}, ${location.locationPostal} ${location.locationCity}`,
		[location]
	)
	const eventFullDate = useMemo(() => {
		momentTZ.locale('nl-be')
		momentTZ.tz.setDefault(timezone)
		return momentTZ(startDate).format('LL')
	}, [startDate])
	const eventDateDayHour = useMemo(() => {
		momentTZ.locale('nl-be')
		momentTZ.tz.setDefault(timezone)
		return `${momentTZ(startDate).format('dddd')} ${momentTZ(startDate).format(
			'HH:mm'
		)}`
	}, [startDate])

	return (
		<section aria-labelledby="event-data">
			<div className="overflow-hidden rounded-lg bg-white shadow">
				<div className="flex flex-col gap-4 p-6">
					<div className="flex">
						<div className="mr-4 flex-shrink-0">
							<CalendarIcon className="-ml-0.5 h-8 w-8" aria-hidden="true" />
						</div>
						<div>
							<h4 className="text-lg">{eventFullDate}</h4>
							<p className="text-base text-gray-500">{eventDateDayHour}</p>
						</div>
					</div>
					<div className="flex">
						<div className="mr-4 flex-shrink-0">
							<MapPinIcon className="-ml-0.5 h-8 w-8" aria-hidden="true" />
						</div>
						<div>
							<h4 className="text-lg font-base">{location.locationName}</h4>
							<p className="text-base text-gray-500">{adress}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default React.memo<Props>(EventData)
