import React from 'react'

type Props = {
  bgUrl: string
  eventName: string
}

const Banner: React.FC<Props> = ({ bgUrl, eventName }) => (
	<section aria-labelledby="event-banner" className="mb-4">
		<div className="overflow-hidden rounded-lg bg-white shadow">
			<img src={bgUrl} alt={eventName} className="object-cover w-full" />
		</div>
	</section>
)

export default React.memo<Props>(Banner)
