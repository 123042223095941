import React from 'react'

type Props = {
  message?: string;
};

const NotFound: React.FC<Props> = ({ message = 'Pagina niet gevonden!' }) => (
	<div className="flex flex-1 items-center justify-center w-full">
		<div className="flex gap-5">
			<div className="font-bold text-4xl sm:text-7xl py-2 sm:py-4">404</div>
			<div className="bg-monster-green-800 w-0.5"></div>
			<div className="text-medium sm:text-lg self-center">{message}</div>
		</div>
	</div>
)

export default React.memo<Props>(NotFound)
