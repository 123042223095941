import React from 'react'
import ShoppingCartItemTicket from './shopping-cart-item-ticket'
import ShoppingCartItemProduct from './shopping-cart-item-product'
import { ShoppingCartItem as ShoppingCartItemType } from '../../types/application-types'
import { groupBy } from 'lodash'

type Props = {
  item: ShoppingCartItemType
}

const ShoppingCartItem: React.FC<Props> = ({ item }) => {
	const groupedTickets = groupBy(item.tickets, (ticket) => {
    return ticket.seatingInfo ? `seated-${ticket.ticketTypeId}` : `general-${ticket.ticketTypeId}`
  })
	return (
		<>
		{Object.entries(groupedTickets).map(([key, tickets]) => {
        const firstTicket = tickets[0]
        const ticketsWithoutSeating = key.startsWith('general-')

        if (ticketsWithoutSeating) {
          // Render grouped general admission tickets as one item
          return (
            <ShoppingCartItemTicket
              key={`shoppingCartItemTicket${firstTicket.ticketTypeId}`}
              ticket={{
                ...firstTicket,
                amount: tickets.length // Set amount to the number of grouped tickets
              }}
            />
          )
        } else {
          // Render seated tickets individually
          return tickets.map((ticket, idx) => (
            <ShoppingCartItemTicket
              key={`shoppingCartItemTicket${ticket.ticketTypeId}${idx}`}
              ticket={ticket}
            />
          ))
        }
      })}
			{/* {item.tickets.map((ticket, idx) => (
				<ShoppingCartItemTicket
					key={`shoppingCartItemTicket${ticket.ticketTypeId}${idx}`}
					ticket={ticket}
				/>
			))} */}
			{item.products.map((product) => (
				<ShoppingCartItemProduct key={`shoppingCartItemProduct${product._id}`} product={product} />
			))}
		</>
	)
}

export default React.memo<Props>(ShoppingCartItem)
