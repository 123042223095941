import React, { useMemo } from 'react'
import Main from '../../components/layout/main'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import Content from '../../components/layout/content'
import ContentLeft from '../../components/layout/content-left'
import ContentRight from '../../components/layout/content-right'
import Banner from '../../components/banner'
import ShoppingCart from '../../components/shopping-cart'
import { useApplication } from '../../providers/application-provider'
import {
	ProductState
} from '../../types/application-types'
import { PriceType } from '../../types/api-types'
import FixedPriceProduct from './components/fixed-price-product'
import RangePriceProduct from './components/range-price-product'

const Products: React.FC = () => {
	const {
		application: { products, reserveProducts }
	} = useApplication()

	/** Methods */
	const onSelectProducts = async (productId: string, amount: number, price?: number) =>
		reserveProducts(productId, amount, price)

	/** State Management */
	const persisting = useMemo(() => {
		if (!products) return false
		return products.some(
			({ state }) =>
				state === ProductState.RESERVING || state === ProductState.UPDATING
		)
	}, [products])

	const renderProducts = (): React.ReactNode[] => {
		return products.map((product) => 
			product.priceType === PriceType.fixed 
				? <FixedPriceProduct disabled={persisting} key={product._id} product={product} onSelectProducts={onSelectProducts} /> 
				: <RangePriceProduct disabled={persisting} key={product._id} product={product} onSelectProducts={onSelectProducts} />
		)
	}

	return (
		<div className="flex flex-col grow shrink-0 basis-auto">
			<Header />
			<Main>
				<Banner />
				<Content>
					<ContentLeft labelledby="product-select">
						<h2 id="products-title" className="text-2xl font-bold mb-6">Selecteer jouw producten</h2>
						<div className="flex flex-col gap-8">{renderProducts()}</div>
					</ContentLeft>
					<ContentRight>
						<ShoppingCart persisting={persisting} />
					</ContentRight>
				</Content>
			</Main>
			<Footer />
		</div>
	)
}

export default React.memo(Products)
