// ENUMS
enum EventType {
  SINGLE = 'SINGLE',
  GROUP = 'GROUP',
  RECURRENCE = 'RECURRENCE',
  ROOT = 'ROOT'
}

enum SaleStatus {
  onSale = 'onSale',
  planned = 'planned',
  past = 'past',
  soldOut = 'soldOut'
}

enum CheckoutStatus {
  NEW = 'NEW',
  ABORTED = 'ABORTED',
  COMPLETE = 'COMPLETE'
}

enum CheckoutType {
  transaction = 'transaction',
  upgrade = 'upgrade',
  rebooking = 'rebooking',
  purchaseintent = 'purchaseintent',
  subscription = 'subscription'
}

enum PriceType {
  fixed = 'fixed',
  range = 'range'
}

enum ProductType {
  voucher = 'voucher',
  product = 'product',
  donation = 'donation'
}

enum DiscountType {
  VAR = 'var',
  FIX = 'fix',
  FIX_PER_ITEM = 'fixPerItem'
}

enum ConcatenationType {
  OR = 'OR',
  AND = 'AND'
}

// API TYPES

type ApiEventTicketRule = {
  _id: string
  concatenation: ConcatenationType
  ticketGroup: string
  min: number
  max: number
  maxAmountPerOrder: number
  minAmountPerOrder: number
}

type ApiEventTicket = {
  id: string
  v: number
  name: string
  price: number
  description: string
  active: boolean
  categoryRef: string
  requiresPersonalization: boolean
  requiresExtraFields: boolean
  sortingKey: number
  minAmountPerOrder: number
  minAmountPerOrderRule: number
  taxRate: number
  styleOptions: Record<string, any>
  ignoredForStartingPrice: boolean
  conditionalAvailability: boolean
  conditionalAvailabilityMode: string
  rules: Array<ApiEventTicketRule>
  ignoreForMaxAmounts: boolean
}

type ApiEventCategory = {
  _id: string
  v: number
  name: string
  ref: string
  seatingReference?: string
  maxAmountPerOrder?: number
  listWithoutSeats?: boolean
  availabilityIndicator?: string
}

type ApiEventSeller = {
  _id: string
  name: string
  description: string
  image: string
  documentImage: string
  url: string
  supportUrl: string
  customLogo: string
  defaultLanguage: string
  locationCountry: string
  taxSetting: {
    taxCalculationMethod: string
  }
}

type ApiEventLocation = {
  locationName: string
  locationCity: string
  locationPostal: string
  locationCountry: string
  locationStreet: string
  geoCode: {
    lat: number
    lng: number
    _id: string
  }
}

type ApiEventTicketGroup= {
  name: string
  tickets: string[]
  _id: string
}

type ApiEvent = {
  _id: string
  eventType: EventType
  name: string
  start: string
  end: string
  url: string
  description: string
  locationName: string
  accentColor: string
  startingPrice: number
  sellStart: string
  extraFields: any[]
  ticketExtraFields: any[]
  seating: boolean
  cXv: number
  cXf: number
  ccXf: number
  ccfXf: number
  sellerId: string
  taxRate: number
  accountsModule: boolean
  accountSettings: {
    enforceAccounts: boolean
    _id: string
  }
  currency: string
  seller: ApiEventSeller
  reservationSettings: {
    option: string
  }
  timezone: string
  saleStatus: SaleStatus
  v: number
  seatingChildEventIds: string[]
  tickets: ApiEventTicket[]
  categories: ApiEventCategory[]
  seatingEventId: string
  seatingConfigurations: {
    availabilityMode: string
    renderer: string
    bestAvailableSeatingConfiguration: {
      enabled: boolean
      enforced: boolean
    }
    orphanConfiguration: {
      minSeatDistance: number
      edgeSeatsOrphaning: boolean
      _id: string
    }
  }
  contingents: any[]
  max: number
  min: number
  groups: Array<ApiEventTicketGroup>
  cartAutomationRules: any[]
  discountGroups: any[]
  theme: string
  styleOptions: {
    headerStyle: string
    hideLocationMap: boolean
    categoryAlignment: number
    showAvailabilityIndicator: boolean
    availabilityIndicatorThresholds: number[]
    hideStartingPrice: boolean
  }
  ticketShopHeaderText: string
  showOtherEvents: boolean
  video: Record<string, any>
  gallery: any[]
  checkinInformation: {
    _id: string
  }
  location: ApiEventLocation
  hardTicketSettings: {
    enabled: boolean
  }
  tracking: {
    enabled: boolean
    sellerId: string
  }
  dataRequestSettings: {
    requiresPersonalization: boolean
    requiresExtraFields: boolean
    repersonalization: boolean
    repersonalizationAllowed: boolean
    repersonalizationDeadline: {
      unit: string
      offset: number
    }
    repersonalizationFee: number
    posPersonalization: string
    skipAddressInfo: boolean
    enforceCompany: boolean
    _id: string
  }
  seoSettings: {
    tags: any[]
  }
  soldOutFallback: {
    soldOutFallbackType: string
  }
  customSettings: {
    showStartDate: boolean
    showStartTime: boolean
    showEndDate: boolean
    showEndTime: boolean
    _id: string
  }
  customTextConfig: {
    _id: string
  }
  upsellSettings: {
    active: boolean
    crossSells: Record<string, any>
    _id: string
  }
  timeSlots: any[]
  multiEventCheckout: boolean
  subscriptionSettings: Record<string, any>
  unlockMode: string
  pricingSettings: {
    dynamicPricing: Record<string, any>
  }
  image?: string
}

type ApiVoucher = {
  _id: string
  code: string
  redeemedAmount: number
}

type ApiRedeemedVoucher = {
  totalRedeemAmount: number
  redeemedVouchers: ApiVoucher[]
}

type ApiAppliedCoupon = {
  _id: string
  code: string
}

type ApiAppliedDiscount = {
  discounts: Array<{
    discountId: string;
    category: string;
    name: string;
    type: string;
    value: number;
    allowedItems: string[];
    maxItemsInCart: number | null;
    maxAbsoluteValue: number | null;
  }>
}

type ApiCheckoutItemTicket = {
  // Required
  amount: number
  price: number
  ticketTypeId: string
  // Optional
  type: string
  _id: string
  name: string
  netPrice: number
  taxRate: number
  triggeredBy: any
  bundleInfo: any
  categoryRef: string
  seatingInfo: {
    _type: number
    statusId: string
    _id: string
    categoryId: string
    name: string
    seatType: string
    sectionName: string
    groupName: string
    rowName: string
    seatName: string
  }
  asHardTicket: boolean
  triggeredAutomations: boolean
  meta: any
}

type ApiCheckoutItemProduct = {
  // Required
  amount: number
  price: number
  productVariantId: string
  type: ProductType
  // Optional
  _id: string
  name: string
  netPrice: number
  taxRate: number
  triggeredBy: any
  bundleInfo: any
  isFulfillable: boolean
}

// Incomplete
type ApiCheckoutItem = {
  // Required
  _id: string
  eventId: string
  regularPrice: number
  realPrice: number
  outerCharge: number
  // Optional
  shopId: string
  channelId: string
  redeemedVouchers: ApiRedeemedVoucher
  appliedCoupons: ApiAppliedCoupon[]
  appliedDiscountInfo: ApiAppliedDiscount
  innerFeeComponents: any
  innerCharge: number
  outerFeeComponents: any
  tickets: ApiCheckoutItemTicket[]
  products: ApiCheckoutItemProduct[]
  additionalItems: any
  seatingReservationToken: string
  subscriptionId: string
}

type ApiAddress = {
  street: string
  line2: string
  postal: string
  city: string
  country: string
  state: string
}

type ApiCheckout = {
  // Required
  _id: string
  secret: string
  status: CheckoutStatus
  type: CheckoutType
  sellerId: string
  items: ApiCheckoutItem[]
  realPrice: number
  // Optional
  company: string
  firstname: string
  name: string
  email: string
  phone: string
  customerId: string
  address: ApiAddress
  deliveryAddress: ApiAddress
  currency: string
  redeemedVouchers: ApiRedeemedVoucher
  preferredLanguage: string
  origin: string
  channel: string
  salesChannelId: string
  posId: string
  userId: string
  extraFields: any
  expiresAt: string
  createdAt: string
  updatedAt: string
}

type ApiPriceRange = {
  min: number
  max: number
}

type ApiVariant = {
  _id: string
  priceType: PriceType
  price: number
  priceRange: ApiPriceRange
  compareAtPrice: number | null
  taxable: boolean
  taxRate: number
  taxServiceTypeId: string
}

type ApiProduct = {
  _id: string
  isFulfillable: boolean
  name: string
  image: string
  description: string
  type: ProductType
  variants: ApiVariant[]
  sellerId: string
  streams: string[]
  voucherSettings: any
  active: boolean
  createdAt: string
  updateAt: string
  __v: number
}

type ApiCoupon = {
  canBeCombined: boolean
  discountType: DiscountType
  discountValue: number
  discountMaxValue: number
  allowAllTickets: boolean
  allowAllEvents: boolean
  // Limit the number of tickets to which this promo code applies within the cart
  limitMaxTicketsInCart: boolean
  maxTicketsInCart: number
  //The maximum number of tickets allowed in the cart for the promo code to apply
  maxTickets: number
  unlocks: any
  code: string
  couponId: string
  name: string
  allowedTickets: Array<string>
  revealAllowedTickets?: boolean
}

export { DiscountType, ProductType, PriceType, ConcatenationType }
export type { 
  ApiEvent, 
  ApiEventTicket, 
  ApiEventCategory, 
  ApiEventTicketGroup, 
  ApiEventTicketRule, 
  ApiCheckout, 
  ApiCheckoutItem, 
  ApiCheckoutItemTicket, 
  ApiCheckoutItemProduct, 
  ApiProduct, 
  ApiCoupon, 
  ApiAppliedCoupon 
}
