import React, { useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon'
import { useApplication } from '../../providers/application-provider'
import type { Event } from '../../types/application-types'


const Header: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const {
		application: { activeEvent }
	} = useApplication()

	/** State Management */
	const ticketsLocation = useMemo(
		() => matchPath('/event/:id/tickets/', location.pathname) != null,
		[location]
	)

	const upsellLocation = matchPath('/event/:id/products/', location.pathname) != null

	/** Methods */
	const onNavigateBack = () => navigate(`/event/${(activeEvent as Event).url}`)

	return (
		<header className="pb-56 relative z-0 overflow-x-hidden">
			{activeEvent?.image && !upsellLocation && (
				<div
					className="bg-cover bg-no-repeat blur-lg absolute top-0 left-0 h-full scale-110 w-full z-0"
					style={{ backgroundImage: `url(${activeEvent.image})` }}
				/>
			)}
			{upsellLocation && activeEvent?.upsellSettings.headerImage && (
				<div
				className="bg-cover bg-no-repeat top-0 left-0 h-60 z-0 mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8"
				style={{ backgroundImage: `url(${activeEvent?.upsellSettings.headerImage})`}}
				// style={{ backgroundImage: `url(https://render.vivenu.com/image?url=${activeEvent?.upsellSettings.headerImage}&height=${240}&forceJPEG=true)` }}
				// https://render.vivenu.com/image?url=https://s3.eu-central-1.amazonaws.com/yt-s3/840c95bf-b0ee-49ec-a380-9584afc3d49e.png&width=1600&forceJPEG=true
			/>
			)}
			<div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="relative flex flex-wrap items-center justify-between">
					{/* Logo */}
					<div className="left-0 flex-shrink-0 py-5 static">
						<a href="#">
							<span className="sr-only">{activeEvent?.seller.name}</span>
							<img
								className="max-h-12 w-auto"
								src={activeEvent?.seller.customLogo}
								alt={activeEvent?.seller.name}
							/>
						</a>
					</div>
					{/* Right section with actions */}
					{ticketsLocation && (
						<div className="flex lg:ml-4 sm:hidden lg:items-center lg:py-5 lg:pr-0.5">
							<button
								type="button"
								onClick={onNavigateBack}
								className="inline-flex items-center self-start rounded bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							>
								<ChevronLeftIcon
									className="-ml-0.5 h-5 w-5"
									aria-hidden="true"
								/>
                Terug
							</button>
						</div>
					)}
				</div>
			</div>
		</header>
	)
}

export default React.memo(Header)
