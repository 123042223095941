'use client'

import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useApplication } from '../../../providers/application-provider'
import { fetchPromotionCode } from '../../../api-handler'
import { ApiCoupon, DiscountType } from '../../../types/api-types'
import { AxiosError } from 'axios'
import { Oval } from 'react-loader-spinner'
import ShoppingCartCouponInvalid from './shopping-cart-coupon-invalid'
import { noop } from 'lodash'

type Props = {
  open: boolean
  onClose: () => void
}

const ShoppingCartCouponModal: React.FC<Props> = ({ open, onClose }) => {
  const {
		application: {
			activeEvent,
			shoppingCart,
			addCoupon
		}
	} = useApplication()
  
  /** State Management */
	const [code, setCode] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

  /** Methods */
	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setCode(value)
	}

	const handleCloseInvalidCoupon = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
		setError(null)
	}

	const handleFetchPromotionCode = async () => {
		setLoading(true)
		setError(null)
		try {
			const response = await fetchPromotionCode(code, activeEvent!.seller._id, activeEvent!._id)

			if (response.data.message === 'valid') {
				const coupon: ApiCoupon = response.data.coupon
				const cartItems = shoppingCart?.items ?? []

        if (coupon.discountType === DiscountType.FIX_PER_ITEM) {
					// Coupon maxTickets applies to the total amount of tickets in the cart
          const ticketCount = cartItems.reduce((count, item) => {
            return count + item.tickets.reduce((ticketCount, ticket) => ticketCount + ticket.amount, 0)
          }, 0)

          if (ticketCount > coupon.maxTickets) {
						// If we have more tickets than the coupon maxTickets, we can't apply the coupon
            setError('Kortingscode kan niet worden toegevoegd.')
            setLoading(false)
            return
          }
        }

        addCoupon(coupon)
        setCode('')
        onClose()
			} else {
				setError('Kortingscode ongeldig!')
			}
		} catch (err) {
			if (err instanceof AxiosError && err.response?.status === 404) {
				setError('Kortingscode niet gevonden!')
			} else {
				setError(err instanceof Error ? err.message : 'An error occurred')
			}
		} finally {
			setLoading(false)
		}
	}

  const onCloseModal = () => {
    setCode('')
    onClose()
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" onClose={error != null ? noop : onCloseModal} className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      onClick={onCloseModal}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title 
                        as="h3" 
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Kortingscode inwisselen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Voer uw kortingscode in.
                        </p>
                        <input
                          type="text"
                          value={code}
                          name="promotioncode"
                          id="promotioncode"
                          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 mt-2"
                          placeholder="Code"
                          onChange={onChangeInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      className={`inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-800 sm:w-auto ${code.length === 0 ? 'cursor-not-allowed' : ''}`}
                      type="button"
                      onClick={handleFetchPromotionCode}
                      disabled={code.length === 0}
                    >
                      {loading ? (
                        <div className="flex justify-center self-center min-w-24">
                          <Oval
                            visible
                            height="24"
                            width="24"
                            color="#FFFFFF"
                            secondaryColor="#FFFFFF80"
                            strokeWidth={3}
                            strokeWidthSecondary={3}
                            ariaLabel="applying-promotion-code"
                          />
                        </div>
                      ) : 'Inwisselen'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ShoppingCartCouponInvalid open={!!error} errorMessage={error ?? ''} onClose={handleCloseInvalidCoupon} />
    </>
  )
}

export default React.memo<Props>(ShoppingCartCouponModal)