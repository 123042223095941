import groupBy from 'lodash/groupBy'
import React, { useMemo } from 'react'
import Main from '../../components/layout/main'
import Banner from '../../components/banner'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import Content from '../../components/layout/content'
import ContentLeft from '../../components/layout/content-left'
import ContentRight from '../../components/layout/content-right'
import TicketCategory from './components/ticket-category'
import ShoppingCart from '../../components/shopping-cart'
import { useApplication } from '../../providers/application-provider'
import {
	Ticket,
	TicketCategory as TicketCategoryType,
	TicketState
} from '../../types/application-types'
import { ApiCoupon } from '../../types/api-types'

const Tickets: React.FC = () => {
	const {
		application: { activeEvent, coupons, reserveTickets }
	} = useApplication()

	/** Methods */
	const onSelectTickets = async (ticketId: string, amount: number) =>
		reserveTickets(ticketId, amount)

	/** State Management */
	const persisting = useMemo(() => {
		if (!activeEvent) return false
		return activeEvent?.tickets.some(
			({ state }) =>
				state === TicketState.RESERVING || state === TicketState.UPDATING
		)
	}, [activeEvent?.tickets])
	
	const ticketsPerCategory: Record<string, Ticket[]> = useMemo(() => {
		if (!activeEvent) return {}
		return groupBy(
			activeEvent.tickets.filter(({ id, active, styleOptions }) => {
				// Is the ticket is hidden in the selection area
				const hidden = styleOptions?.hiddenInSelectionArea === true
				// Is the ticket revealed by a coupon?
				const revealedByCoupon = coupons.some((coupon) => 
					coupon.allowedTickets?.includes(id) && coupon.revealAllowedTickets === true
				)
				// Is the ticket active and not hidden?
				return active && (!hidden || revealedByCoupon)
			}),
			'categoryRef'
		)
	}, [activeEvent?.tickets, coupons])

	const getCouponWithLowestMaxTickets = () => {
		if (coupons.length === 0) {
			return null
		}
	
		return coupons.reduce((lowestCoupon, currentCoupon) => {
			// If current coupon doesn't have maxTickets, skip it
			if (currentCoupon.maxTickets == null) {
				return lowestCoupon
			}
	
			// If lowestCoupon isn't set or doesn't have maxTickets, use currentCoupon
			if (!lowestCoupon || lowestCoupon.maxTickets === undefined) {
				return currentCoupon
			}
	
			// Compare maxTickets values
			return currentCoupon.maxTickets < lowestCoupon.maxTickets
				? currentCoupon
				: lowestCoupon
		}, null as ApiCoupon | null)
	}

	const renderTicketCategories = () => {
		if (!activeEvent) return []

		const mostRestrictiveCoupon = getCouponWithLowestMaxTickets()
		const totalAmountOfTicketsForEvent = Object.values(ticketsPerCategory).reduce((acc, tickets) => acc + tickets.reduce((acc, { amount }) => acc + amount, 0), 0)
		
		const maxAmountEvent = activeEvent.max - totalAmountOfTicketsForEvent
		const maxAmountEventWithCoupon = mostRestrictiveCoupon ? mostRestrictiveCoupon.maxTickets - totalAmountOfTicketsForEvent : Number.POSITIVE_INFINITY
		
		return activeEvent.categories.reduce(
			(acc: React.ReactNode[], category: TicketCategoryType, idx: number) => {
				const ticketsForCategory = ticketsPerCategory[category.ref] || []
				if (ticketsForCategory.length === 0) return acc

				const totalAmountOfTicketsForCategory = ticketsForCategory.reduce((acc, { amount }) => acc + amount, 0)
				// const maxAmountCategory = category.maxAmountPerOrder < category.v 
				// 	? category.maxAmountPerOrder - totalAmountOfTicketsForCategory 
				// 	: category.v - totalAmountOfTicketsForCategory

				const maxAmountCategory = category.maxAmountPerOrder != null
					? (category.maxAmountPerOrder < category.v 
						? category.maxAmountPerOrder - totalAmountOfTicketsForCategory 
						: category.v - totalAmountOfTicketsForCategory)
					: category.v - totalAmountOfTicketsForCategory

				const maxAmount = Math.min(maxAmountEvent, maxAmountCategory, maxAmountEventWithCoupon)
					
				return [
					...acc,
					<TicketCategory
						key={`ticketCategory${idx}`}
						category={category}
						disabled={persisting}
						tickets={ticketsForCategory}
						maxAmount={maxAmount}
						onSelectTickets={onSelectTickets}
					/>
				]
			},
			[]
		)
	}

	return (
		<div className="flex flex-col grow shrink-0 basis-auto">
			<Header />
			<Main>
				<Banner />
				<Content>
					<ContentLeft labelledby="tickets-select">
						<h2 id="tickets-title" className="text-2xl font-bold mb-6">Selecteer jouw tickets</h2>
						<div className="flex flex-col gap-8">{renderTicketCategories()}</div>
					</ContentLeft>
					<ContentRight>
						<ShoppingCart persisting={persisting} />
					</ContentRight>
				</Content>
			</Main>
			<Footer />
		</div>
	)
}

export default React.memo(Tickets)
