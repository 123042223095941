import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ClockIcon from '@heroicons/react/24/outline/ClockIcon'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import { useApplication } from '../../providers/application-provider'

type Props = {
  expireTime: string
}

const ShoppingCartCountdownTimer: React.FC<Props> = ({ expireTime }) => {
	/** State Management */
	const { application: { setCheckoutExpired } } = useApplication()
	const [countdown, setCountdown] = useState('')

	/** Methods */
	const updateCountdown = () => {
		const diffInSeconds = moment(expireTime).diff(moment(), 'seconds')

		if (diffInSeconds <= 0) {
			// Handle expired state or render nothing
			setCountdown('00:00')
			setCheckoutExpired(true)
		} else {
			if (diffInSeconds < 3600) {
				setCountdown(moment.utc(diffInSeconds * 1000).format('mm:ss'))
			} else {
				setCountdown(moment.utc(diffInSeconds * 1000).format('HH:mm:ss'))
			}
		}
	}
	
	/** Lifecycle Methods */
	useEffect(() => {
		const intervalId = setInterval(updateCountdown, 1000)

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId)
	}, [expireTime])

	useEffect(() => {
		updateCountdown()
	}, [])

	return (
		<div className="flex self-end min-w-24 justify-end">
			<div className="flex gap-1 items-center text-gray-500 ">
				{countdown === '00:00' ? (
					<ExclamationTriangleIcon
						className="h-6 w-6 text-red-600"
						aria-hidden="true"
					/>
				) : (
					<ClockIcon className="h-6 w-6" aria-hidden="true" />
				)}
				<span className={countdown === '00:00' ? 'text-red-500' : ''}>
					{countdown}
				</span>
			</div>
		</div>
	)
}

export default React.memo<Props>(ShoppingCartCountdownTimer)
