import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon'
import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon'
import { useApplication } from '../providers/application-provider'
import type { Event } from '../types/application-types'

const Banner: React.FC = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const {
		application: { activeEvent }
	} = useApplication()

	/** Methods */
	const onNavigateBack = () => {
		if (location.pathname.includes('/tickets')) {
			navigate(`${location.pathname.split('/').slice(0, -1).join('/')}?${searchParams}`)
		}  else if (location.pathname.includes('/products')) {
			navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/tickets?${searchParams}`)
		}
	}

	return (
		<section aria-labelledby="profile-overview-title" className="mb-4">
			<div className="overflow-hidden rounded-lg bg-white shadow">
				<div className="bg-white p-6 flex items-center text-gray-600 justify-between gap-14">
					<div className="flex gap-2 items-center">
						<CalendarIcon
							className="flex -ml-0.5 h-6 w-6 shrink-0 self-start pt-0.5 text-primary"
							aria-hidden="true"
						/>
						<h2 id="event-title" className="text-xl font-medium">
							{activeEvent?.name}
						</h2>
					</div>
					<button
						type="button"
						onClick={onNavigateBack}
						className="flex items-center self-start rounded bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						<ChevronLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
						Terug
					</button>
				</div>
			</div>
		</section>
	)
}

export default React.memo(Banner)
