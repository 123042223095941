import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Main from '../../components/layout/main'
import Banner from './components/banner'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import Content from '../../components/layout/content'
import EventData from './components/event-data'
import BuyTickets from './components/buy-tickets'
import Description from './components/description'
import ContentLeft from '../../components/layout/content-left'
import ContentRight from '../../components/layout/content-right'
import { useApplication } from '../../providers/application-provider'

const EventInfo: React.FC = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const {
		application: { activeEvent }
	} = useApplication()

	/** Methods */
	const onNavigateToTickets = () => navigate(`tickets?${searchParams}`)

	if (!activeEvent) return null

	return (
		<>
			<div className="flex flex-col grow shrink-0 basis-auto">
				<>
					<Header />
					<Main>
						{activeEvent!.image && (
							<Banner
								bgUrl={activeEvent!.image}
								eventName={activeEvent!.name}
							/>
						)}
						<Content>
							<ContentLeft labelledby="event-description">
								<h2 id="event-title" className="text-3xl font-bold">
									{activeEvent!.name}
								</h2>
								<Description description={activeEvent!.description} />
							</ContentLeft>
							<ContentRight>
								<BuyTickets
									saleStatus={activeEvent!.saleStatus}
									hideStartingPrice={activeEvent!.hideStartingPrice}
									startingPrice={activeEvent!.startingPrice}
									onNavigateToTickets={onNavigateToTickets}
								/>
								<EventData
									location={activeEvent!.location}
									timezone={activeEvent!.timezone}
									startDate={activeEvent!.start}
								/>
							</ContentRight>
						</Content>
					</Main>
					<Footer />
				</>
			</div>
		</>
	)
}

export default React.memo(EventInfo)
